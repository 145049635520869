import { ReactElement } from 'react';

import CONSTANTS from '../../constants';
import { SKlogo, LockIcon } from '../../icons';

import './desktop-header.scss';

type TProps = {
	isMemberKeyOnly: boolean;
}

function DesktopHeader({ isMemberKeyOnly }: TProps): ReactElement {
	const baseClassName = 'desktop-header';
	const classNames = {
		component: isMemberKeyOnly ? `${baseClassName} ${baseClassName}--member-key-only` : baseClassName,
		text: `${baseClassName}__text`,
		description: `${baseClassName}__description`,
		link: `${baseClassName}__link`,
	};

	return (
		<header className={classNames.component}>
			<a
				className={classNames.link}
				href={CONSTANTS.EXTERNAL_URLS.SHAREKEY_MAIN}
				target='_blank'
				rel='noopener'
			>
				<SKlogo />
			</a>
			<div className={classNames.text}>
				{isMemberKeyOnly
					? <>The Swiss <br /> Collaboration App <br /> for Business Privacy</>
					: <>You are invited <br/> to join Sharekey</>
				}
			</div>
			<div className={classNames.description}>
				{isMemberKeyOnly
					? <><LockIcon /> Secure Business Privacy</>
					: <>The Swiss Collaboration App <br/> for Business Privacy</>
				}
			</div>
		</header>
	);
}

export default DesktopHeader;
