const MOBILE_HEADER_TEXTS = {
	TITLE: 'You are invited to join Sharekey',
	DESCRIPTION: 'The Swiss Collaboration App for Business Privacy',
	CONNECT_ONLY: {
		TITLE: 'The Swiss Collaboration App for Business Privacy',
		DESCRIPTION: 'Secure Business Privacy',
	},
};

export default MOBILE_HEADER_TEXTS;
