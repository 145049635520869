const CONSTANTS = {
	INVITATION_CODE: {
		LENGTH: 12,
		REGEX: /^[A-Z0-9]{12}$/,
		OLD: {
			LENGTH: 8,
			REGEX: /^[A-z0-9]{8}$/i,
		},
	},
	USER_PID: {
		LENGTH: 8,
		REGEX: /^[A-Z0-9]{7}[0-9]{1}$/,
	},
	EXTERNAL_URLS: {
		SHAREKEY_MAIN: `https://sharekey.com`,
		SHAREKEY_APP: 'https://app.sharekey.com',
		SHAREKEY_APP_SHORT: 'app.sharekey.com',
		SHAREKEY_APP_PROTOCOL: window.location.origin.includes('dev.invite.sharekey.com')
			? 'com.sharekey.desktopapp.stg://'
			: 'com.sharekey.desktopapp://',
		PRIVACY_POLICY: 'https://sharekey.com/info/privacy-policy.pdf',
		TERMS_OF_SERVICE: 'https://sharekey.com/info/terms-of-service.pdf',
		SUPPORT: 'https://sharekey.com/support',
		RIGHTS: 'https://www.mapreuve.com/en/attestation-depot-preuve.php?id_depot=7D5F3B48B0269EA939BF1179B4F99C4F97D58B149976CD816F99757F8F419A53',
		DOWNLOAD: {
			DESKTOP: 'https://sharekey.com/download/',
			ANDROID: 'https://play.google.com/store/apps/details?id=com.sharekey',
			IOS: 'https://apps.apple.com/by/app/sharekey/id1460373909',
		},
	},
	DESKTOP_APP_STATE: {
		INSTALLED: 'installed',
		NOT_INSTALLED: 'not_installed',
		INDETERMINATE: 'indeterminate',
	},
	TIME_TO_DETECT_DESKTOP: 500,
	ADDITIONAL_TIME_TO_LOAD_FAVICON: 100,
	MAX_PHONE_SCREEN_WIDTH: 500,
};

export default CONSTANTS;
