import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import handleProperVh from './helpers/handleProperVh';
import reportWebVitals from './reportWebVitals';

import App from './App';

import './index.scss';

if (isMobile) {
	handleProperVh();
	window.addEventListener('resize', handleProperVh);
}

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<App />
		</Router>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
