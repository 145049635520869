import { isSafari } from 'react-device-detect';

function tryOpenApp(appUrl: string): void {
	try {
		if (isSafari) {
			const iframe = document.createElement('iframe');

			iframe.src = appUrl;
			iframe.style.display = 'none';

			document.body.appendChild(iframe);

			setTimeout(() => {
				document.body.removeChild(iframe);
			}, 25);
		} else
			window.location.replace(appUrl);
	} catch (err) {
		console.warn(err);
	}
}

export default tryOpenApp;
