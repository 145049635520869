import { ReactElement, useContext } from 'react';
import QRCode from 'qrcode.react';
import { isTablet, isMobileOnly } from 'react-device-detect';

import CONSTANTS from '../../constants';
import handleIndeterminateAppLinkClick from '../../helpers/handleIndeterminateAppLinkClick';
import tryOpenApp from '../../helpers/tryOpenApp';
import DesktopAppInstalledContext from '../../DesktopAppInstalledContext';

import { QRcodeLogoIcon } from '../../icons';

import './connect-section.scss';

type TProps = {
	userPID: string;
	mainContentTextClassname: string;
};

function ConnectSection({ userPID, mainContentTextClassname }: TProps): ReactElement {
	const { desktopAppInstalledState } = useContext(DesktopAppInstalledContext);
	const baseClassName = 'connect-section';
	const qrCodeBaseClassName = `${baseClassName}__qrCode`;
	const isMobile = isMobileOnly
		|| window.screen.width < CONSTANTS.MAX_PHONE_SCREEN_WIDTH;
	const qrCodeAdditionalClassname = isTablet
		? `${qrCodeBaseClassName}--tablet`
		: isMobile
			? `${qrCodeBaseClassName}--mobile`
			: '';
	const classNames = {
		component: baseClassName,
		title: `${baseClassName}__title`,
		content: `${mainContentTextClassname} ${baseClassName}__content`,
		contentLink: `${baseClassName}__content-link`,
		recommendation: `${baseClassName}__recommendation`,
		logo: `${baseClassName}__logo`,
		qrCode: `${qrCodeBaseClassName} ${qrCodeAdditionalClassname}`,
	};
	const linksKeyPart = `member/${userPID}`;
	const connectLinkWeb = `${CONSTANTS.EXTERNAL_URLS.SHAREKEY_APP}/${linksKeyPart}`;
	const connectLinkDesktop = `${CONSTANTS.EXTERNAL_URLS.SHAREKEY_APP_PROTOCOL}${linksKeyPart}`;
	const shortConnectLink = `${CONSTANTS.EXTERNAL_URLS.SHAREKEY_APP_SHORT}/${linksKeyPart}`;
	const isAppInstalled = desktopAppInstalledState === CONSTANTS.DESKTOP_APP_STATE.INSTALLED;
	const isAppIndeterminate = desktopAppInstalledState === CONSTANTS.DESKTOP_APP_STATE.INDETERMINATE;
	const qrCodeStyle = isTablet
		? { width: 115, height: 115 }
		: isMobile
			? { width: 120, height: 120 }
			: { width: 101, height: 101 };

	const onClickIndeterminateLink = (e: React.MouseEvent) =>
		handleIndeterminateAppLinkClick(e, connectLinkDesktop, connectLinkWeb);

	const onClickDesktopLink = () => tryOpenApp(connectLinkDesktop);

	const linkOutput = isAppInstalled
		? <span
			className={classNames.contentLink}
			onClick={onClickDesktopLink}
		>
			{shortConnectLink}
		</span>
		: <a
			className={classNames.contentLink}
			href={connectLinkWeb}
			target='_blank'
			{...(isAppIndeterminate && { onClick: onClickIndeterminateLink })}
		>
			{shortConnectLink}
		</a>;

	return (
		<section className={classNames.component}>
			<h1 className={classNames.title}>
				3. Connect
			</h1>
			<div className={classNames.content}>
				Click&nbsp;{linkOutput}&nbsp;to connect {!isMobileOnly && <br />}
				or scan this QR code using the Sharekey App.
			</div>
			<div className={classNames.qrCode}>
				<QRCode
					value={connectLinkWeb}
					bgColor='white'
					fgColor='#355069'
					level={'M'}
					style={qrCodeStyle}
				/>
				<QRcodeLogoIcon className={classNames.logo} />
			</div>
		</section>
	);
}

export default ConnectSection;
