/* eslint-disable max-len */
import { FC } from 'react';

const SwissPrivacyIcon: FC = () => {
	return (
		<svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<rect width='24.3902' height='24.3284' fill='#3FC0C4'/>
			<rect x='9.93555' y='4.50391' width='4.51671' height='15.3179' fill='white'/>
			<rect x='4.51562' y='14.4141' width='4.50527' height='15.3568' transform='rotate(-90 4.51562 14.4141)' fill='white'/>
		</svg>
	);
};

export default SwissPrivacyIcon;
