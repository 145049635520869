import { ReactElement } from 'react';
import { isTablet } from 'react-device-detect';

import CONSTANTS from '../../constants';
import getCurrentYear from '../../helpers/getCurrentYear';

import './legal-info.scss';

function LegalInfo(): ReactElement {
	const baseClassName = 'footer-legal-info';
	const itemClassname = `${baseClassName}__item`;
	const classNames = {
		component: `${baseClassName} ${isTablet ? `${baseClassName}--tablet` : ''}`,
		item: `${itemClassname} ${itemClassname}--first`,
		links: `${baseClassName}__links`,
		linkItem: `${itemClassname} ${baseClassName}__links-item`,
	};
	const { PRIVACY_POLICY, TERMS_OF_SERVICE, SUPPORT, RIGHTS } = CONSTANTS.EXTERNAL_URLS;
	const currentYear = getCurrentYear();

	return (
		<div className={classNames.component}>
			<a
				className={classNames.item}
				href={RIGHTS}
				target='_blank'
			>
				&#169; {currentYear} Sharekey. All rights reserved.
			</a>
			<div className={classNames.links}>
				<a
					href={TERMS_OF_SERVICE}
					className={classNames.linkItem}
					target='_blank'
				>
					Terms of Service
				</a>
				<a
					href={PRIVACY_POLICY}
					className={classNames.linkItem}
					target='_blank'
				>
					Privacy Policy
				</a>
				<a
					href={SUPPORT}
					className={classNames.linkItem}
					target='_blank'
				>
					Support
				</a>
			</div>
		</div>
	);
}

export default LegalInfo;
