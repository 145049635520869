/* eslint-disable max-len */
import { FC } from 'react';

const NoBackdoorIcon: FC = () => {
	return (
		<svg width='26' height='25' viewBox='0 0 26 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<rect x='0.730469' width='24.3902' height='24.3284' fill='#3FC0C4'/>
			<path d='M7.05664 11.2631L11.7044 15.8327L18.8001 8.10938' stroke='white' strokeWidth='2.71618' strokeLinecap='round' strokeLinejoin='round'/>
		</svg>
	);
};

export default NoBackdoorIcon;
