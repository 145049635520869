/* eslint-disable max-len */
import { FC } from 'react';

const SKlogoMobileIcon: FC = () => {
	return (
		<svg width='48' height='56' viewBox='0 0 48 56' fill='none' xmlns='http://www.w3.org/2000/svg'>
			<path fillRule='evenodd' clipRule='evenodd' d='M18.5113 50.4925C14.7539 47.7798 13.9071 42.5348 16.6198 38.7774C16.6259 38.7691 16.6319 38.7607 16.638 38.7523C17.1518 38.0463 17.5426 37.5088 17.8106 37.1399C18.4263 36.2926 20.7006 33.2128 24.6337 27.9005C27.3666 24.2091 32.5639 23.4099 36.2797 26.1096C39.9882 28.8039 40.8175 33.9908 38.134 37.7072L30.2731 48.5939C27.5494 52.366 22.2835 53.216 18.5113 50.4925Z' fill='url(#skMobileLogo_paint0_linear)'/>
			<path opacity='0.490149' fillRule='evenodd' clipRule='evenodd' d='M31.4323 46.9873C31.8828 44.5972 31.9992 42.9137 31.7814 41.9368C30.9924 38.3981 28.5159 36.2059 26.2504 35.5189C23.0468 34.5473 20.077 35.3096 17.341 37.8059C20.4599 33.5478 22.2733 31.0693 22.781 30.3705C23.2887 29.6716 25.7253 26.459 30.0908 20.7327L43.514 30.4852L31.4323 46.9873Z' fill='black' fillOpacity='0.166412'/>
			<path fillRule='evenodd' clipRule='evenodd' d='M11.4921 29.2688L10.8846 28.8275C7.34298 26.2543 6.55787 21.2973 9.13101 17.7557C9.5635 17.1604 9.996 16.5652 10.4285 15.9699C11.0373 15.132 13.1993 12.0769 16.9147 6.80461L16.9147 6.80461C19.5525 3.0614 24.7254 2.1653 28.4686 4.80313C28.5099 4.83225 28.551 4.86176 28.5918 4.89163C32.3586 7.65062 33.1823 12.9376 30.4332 16.7117L22.5582 27.5231C19.9829 31.0585 15.0307 31.8398 11.4921 29.2688Z' fill='url(#paint1_linear)'/>
			<path fillRule='evenodd' clipRule='evenodd' d='M15.5193 8.85884C12.5729 15.9035 18.99 25.2722 28.6057 20.7837C29.0253 20.5878 29.453 20.4324 29.863 20.2536C32.0845 19.2851 34.3162 18.9309 36.5581 19.1912L36.5581 19.1912C41.1055 19.7192 44.3639 23.8336 43.836 28.381C43.6704 29.8066 43.1376 31.1648 42.2897 32.3227L33.99 43.6556L31.4247 46.8265C34.8484 39.3318 28.3518 30.358 18.7573 34.2525C16.8814 35.0139 14.6655 35.7792 13.0155 36.065C11.8265 36.271 10.5828 36.1899 9.28423 35.8218L9.28423 35.8218C4.87982 34.5732 2.32151 29.9906 3.57009 25.5861C3.85336 24.5869 4.32189 23.6498 4.95129 22.8236C11.2187 14.5968 14.7414 9.9419 15.5193 8.85884Z' fill='url(#paint2_linear)'/>
			<defs>
				<linearGradient id='skMobileLogo_paint0_linear' x1='21.501' y1='51.9088' x2='30.9542' y2='30.3586' gradientUnits='userSpaceOnUse'>
					<stop stopColor='#0C5495'/>
					<stop offset='1' stopColor='#0F5A9D'/>
				</linearGradient>
				<linearGradient id='paint1_linear' x1='27.2294' y1='4.44351' x2='-5.40608' y2='-1.10723' gradientUnits='userSpaceOnUse'>
					<stop stopColor='#73F7DD'/>
					<stop offset='1' stopColor='#5FBFC3'/>
				</linearGradient>
				<linearGradient id='paint2_linear' x1='8.8111' y1='17.2996' x2='40.8632' y2='35.6219' gradientUnits='userSpaceOnUse'>
					<stop stopColor='#73F7DD'/>
					<stop offset='1' stopColor='#084E92'/>
				</linearGradient>
			</defs>
		</svg>
	);
};

export default SKlogoMobileIcon;
