import { ReactElement } from 'react';
import { isTablet } from 'react-device-detect';

import LegalInfo from '../LegalInfo';

import { GDPRreadyIcon, NoBackdoorIcon, SwissPrivacyIcon } from '../../icons';

import './desktop-footer.scss';

function DesktopFooter(): ReactElement {
	const baseClassName = 'footer';
	const classNames = {
		component: `${baseClassName} ${isTablet ? `${baseClassName}--tablet` : ''}`,
		info: `${baseClassName}__info`,
		infoItem: `${baseClassName}__info-item`,
		infoItemText: `${baseClassName}__info-item-text`,
		legalInfo: `${baseClassName}__legal-info`,
	};

	return (
		<footer className={classNames.component}>
			<div className={classNames.info}>
				<div className={classNames.infoItem}>
					<SwissPrivacyIcon />
					<div className={classNames.infoItemText}>
						SWISS<br/>privacy
					</div>
				</div>
				<div className={classNames.infoItem}>
					<GDPRreadyIcon />
					<div className={classNames.infoItemText}>
						GDPR<br/>ready
					</div>
				</div>
				<div className={classNames.infoItem}>
					<NoBackdoorIcon />
					<div className={classNames.infoItemText}>
						NO<br/>backdoor
					</div>
				</div>
			</div>
			<LegalInfo />
		</footer>
	);
}

export default DesktopFooter;
