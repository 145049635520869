import { ReactElement } from 'react';

import LegalInfo from '../LegalInfo';
import MainContent from '../MainContent';

import './mobile-page.scss';

type TProps = {
	invitationCode: string;
	userPID: string;
	isInvitationCodeOnly: boolean;
	isMemberKeyOnly: boolean;
};

function MobilePage(props: TProps): ReactElement {
	const { isInvitationCodeOnly, isMemberKeyOnly } = props;
	const baseClassName = 'mobile-page';
	const inviteOnlyClassName = isInvitationCodeOnly ? `${baseClassName}--invite-only` : '';
	const memberKeyOnlyClassName = isMemberKeyOnly ? `${baseClassName}--member-key-only` : '';
	const componentClassName = `${baseClassName} ${inviteOnlyClassName} ${memberKeyOnlyClassName}`;
	const footerClassName = `${baseClassName}__footer`;

	return (
		<div className={componentClassName}>
			<MainContent {...props} />
			<footer className={footerClassName}>
				<LegalInfo />
			</footer>
		</div>
	);
}

export default MobilePage;
