import { createContext, Dispatch, SetStateAction } from 'react';
import CONSTANTS from './constants';

interface IContext {
	desktopAppInstalledState: string,
	setDesktopAppInstalledState?: Dispatch<SetStateAction<string>>,
}

const DesktopAppInstalledContext = createContext<IContext>({
	desktopAppInstalledState: CONSTANTS.DESKTOP_APP_STATE.NOT_INSTALLED,
});

export default DesktopAppInstalledContext;
