import { ReactElement } from 'react';
import { isTablet } from 'react-device-detect';

import DesktopContainer from '../DesktopContainer';
import MainContent from '../MainContent';

import './desktop-page.scss';

type TProps = {
	invitationCode: string;
	userPID: string;
	isInvitationCodeOnly: boolean;
	isMemberKeyOnly: boolean;
};

function DesktopPage(props: TProps): ReactElement {
	const { isMemberKeyOnly } = props;
	const desktopContentContainerClassName = 'desktop-content-container';

	return (
		<DesktopContainer isMemberKeyOnly={isMemberKeyOnly}>
			{isTablet
				? <MainContent {...props} />
				: <div className={desktopContentContainerClassName}>
					<MainContent {...props} />
				</div>
			}
		</DesktopContainer>
	);
}

export default DesktopPage;
