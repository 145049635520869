import { ReactElement, useContext } from 'react';
import { isTablet, isMobileOnly } from 'react-device-detect';

import CONSTANTS from '../../constants';
import DesktopAppInstalledContext from '../../DesktopAppInstalledContext';

import {
	DownloadAndroidIcon,
	DownloadDesktopIcon,
	DownloadIOSIcon,
	MobileDownloadAndroidIcon,
	MobileDownloadIOSIcon,
	TabletDownloadAndroidIcon,
	TabletDownloadDesktopIcon,
	TabletDownloadIOSIcon,
} from '../../icons';

import './install-sharekey-section.scss';

type TProps = {
	mainContentTextClassname: string;
	isMemberKeyOnly: boolean;
};

function InstallSharekeySection({ mainContentTextClassname, isMemberKeyOnly }: TProps): ReactElement {
	const { desktopAppInstalledState, setDesktopAppInstalledState } = useContext(DesktopAppInstalledContext);
	const baseClassName = 'install-sharekey-section';
	const classNames = {
		component: baseClassName,
		title: `${baseClassName}__title`,
		buttonsContainer: `${baseClassName}__buttons-container`,
		button: `${baseClassName}__button`,
		recommendation: `${baseClassName}__recommendation ${isTablet ? `${baseClassName}__recommendation--tablet` : ''}`,
		webAlternative: mainContentTextClassname,
	};

	const { ANDROID, DESKTOP, IOS } = CONSTANTS.EXTERNAL_URLS.DOWNLOAD;
	const isMobile = isMobileOnly
		|| window.screen.width < CONSTANTS.MAX_PHONE_SCREEN_WIDTH;

	const setIndeterminateAppInstalledState = () => {
		if (desktopAppInstalledState === CONSTANTS.DESKTOP_APP_STATE.NOT_INSTALLED)
			setDesktopAppInstalledState?.(CONSTANTS.DESKTOP_APP_STATE.INDETERMINATE);
	};

	return (
		<section className={classNames.component}>
			<h1 className={classNames.title}>
				1. Install Sharekey
			</h1>
			<div className={classNames.buttonsContainer}>
				{!isMobile
					&& <a
						href={DESKTOP}
						target='_blank'
						className={classNames.button}
					>
						{isTablet
							? <TabletDownloadDesktopIcon />
							: <DownloadDesktopIcon onClick={setIndeterminateAppInstalledState} />
						}
					</a>
				}
				<a
					href={ANDROID}
					target='_blank'
					className={classNames.button}
				>
					{isTablet
						? <TabletDownloadAndroidIcon />
						: isMobileOnly
							? <MobileDownloadAndroidIcon />
							: <DownloadAndroidIcon />
					}
				</a>
				<a
					href={IOS}
					target='_blank'
					className={classNames.button}
				>
					{isTablet
						? <TabletDownloadIOSIcon />
						: isMobileOnly
							? <MobileDownloadIOSIcon />
							: <DownloadIOSIcon />
					}
				</a>
			</div>
			{!isMobile
				&& <div className={classNames.webAlternative}>
					or use the Web App (iPad)&nbsp;
					<a
						target='_blank'
						href={CONSTANTS.EXTERNAL_URLS.SHAREKEY_APP}
					>
						{CONSTANTS.EXTERNAL_URLS.SHAREKEY_APP_SHORT}
					</a>
				</div>
			}
			{isMemberKeyOnly
				&& <div className={classNames.recommendation}>
					If you already have Sharekey installed, just open this link {!isMobileOnly && <br />}
					on any device with Sharekey.
				</div>
			}
		</section>
	);
}

export default InstallSharekeySection;
