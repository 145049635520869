import { ReactElement } from 'react';
import { isTablet } from 'react-device-detect';

import TEXTS from './TEXTS';
import CONSTANTS from '../../constants';
import { SKlogoMobileIcon, LockIcon } from '../../icons';

import './mobile-header.scss';

type TProps = {
	isMemberKeyOnly: boolean;
}

function MobileHeader({ isMemberKeyOnly }: TProps): ReactElement {
	const baseClassName = 'mobile-header';
	const componentClassname = `${baseClassName} ${isTablet ? `${baseClassName}--tablet` : ''}`;
	const textContainerClassname = `${baseClassName}__text-container`;
	const titleClassname = `${baseClassName}__title`;
	const textClassname = `${baseClassName}__text`;
	const linkClassname = `${baseClassName}__link`;
	const baseTexts = isMemberKeyOnly ? TEXTS.CONNECT_ONLY : TEXTS;

	return (
		<div className={componentClassname}>
			<a
				className={linkClassname}
				href={CONSTANTS.EXTERNAL_URLS.SHAREKEY_MAIN}
				target='_blank'
				rel='noopener'
			>
				<SKlogoMobileIcon />
			</a>
			<div className={textContainerClassname}>
				<div className={titleClassname}>{baseTexts.TITLE}</div>
				<div className={textClassname}>
					{isMemberKeyOnly && <LockIcon />} {baseTexts.DESCRIPTION}
				</div>
			</div>
		</div>
	);
}

export default MobileHeader;
