import { ReactElement, ReactNode } from 'react';
import { isTablet } from 'react-device-detect';

import DesktopHeader from '../DesktopHeader';
import DesktopFooter from '../DesktopFooter';

import './desktop-container.scss';

type TProps = {
	children: ReactNode;
	isMemberKeyOnly: boolean;
};

function DesktopContainer({ children, isMemberKeyOnly }: TProps): ReactElement {
	const baseClassName = 'desktop-container';
	const componentClassName = `${baseClassName} ${isTablet ? `${baseClassName}--tablet` : ''}`;

	return (
		<div className={componentClassName}>
			{!isTablet && <DesktopHeader isMemberKeyOnly={isMemberKeyOnly} />}
			{children}
			<DesktopFooter />
		</div>
	);
}

export default DesktopContainer;
