import { isMobile } from 'react-device-detect';

import CONSTANTS from '../constants';
import tryOpenApp from './tryOpenApp';

function handleIndeterminateAppLinkClick(e: React.MouseEvent, urlForDesktop: string, urlForWeb: string): void {
	let timeout: number | undefined;

	function handleBlurOnProtocolLaunch() {
		clearTimeout(timeout);
		timeout = undefined;

		window.removeEventListener('blur', handleBlurOnProtocolLaunch);
	}

	if (!isMobile) {
		e.preventDefault();

		window.addEventListener('blur', handleBlurOnProtocolLaunch);

		timeout = window.setTimeout(() => window.location.href = urlForWeb, CONSTANTS.TIME_TO_DETECT_DESKTOP);

		tryOpenApp(urlForDesktop);
	}
}

export default handleIndeterminateAppLinkClick;
