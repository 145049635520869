import { ReactElement } from 'react';
import { isMobileOnly } from 'react-device-detect';

import './get-invited-section.scss';

type TProps = {
	mainContentTextClassname: string;
}

function GetInvitedSection({ mainContentTextClassname }: TProps): ReactElement {
	const baseClassName = 'get-invited-section';
	const classNames = {
		component: baseClassName,
		title: `${baseClassName}__title`,
		content: `${mainContentTextClassname} ${baseClassName}__content`,
	};

	return (
		<section className={classNames.component}>
			<h1 className={classNames.title}>
				2. Get Invited
			</h1>
			<div className={classNames.content}>
				Get invited by another Sharekey Member, {!isMobileOnly && <br />}
				create your account and then proceed to step 3.
			</div>
		</section>
	);
}

export default GetInvitedSection;
