import { ReactElement, useEffect, useState } from 'react';
import {
	isMobileOnly,
	isTablet,
	isMobile as isMobileOrTablet,
	isSafari,
} from 'react-device-detect';
import { useLocation } from 'react-router-dom';

import CONSTANTS from './constants';
import checkInvitationCodeFromURL from './helpers/checkInvitationCodeFromURL';
import checkMemberKeyFromURL from './helpers/checkMemberKeyFromURL';
import tryOpenApp from './helpers/tryOpenApp';
import DesktopAppInstalledContext from './DesktopAppInstalledContext';

import DesktopPage from './components/DesktopPage';
import MobilePage from './components/MobilePage';

import './App.scss';

function App(): ReactElement | null {
	const [desktopAppInstalledState, setDesktopAppInstalledState] = useState(CONSTANTS.DESKTOP_APP_STATE.NOT_INSTALLED);
	const [invitationCode, setInvitationCode] = useState('');
	const [userPID, setUserPID] = useState('');
	const [isInvitationCodeOnly, setIsInvitationCodeOnly] = useState(false);
	const [isMemberKeyOnly, setIsMemberKeyOnly] = useState(false);
	const { pathname, search } = useLocation();
	const isMobile = isMobileOnly
		|| window.screen.width < CONSTANTS.MAX_PHONE_SCREEN_WIDTH;

	const processProtocolLaunch = ({
		invitationCode, userToConnectPID,
	}: { invitationCode?: string; userToConnectPID?: string}): void => {
		function handleBlurOnProtocolLaunch() {
			setDesktopAppInstalledState(CONSTANTS.DESKTOP_APP_STATE.INSTALLED);

			window.removeEventListener('blur', handleBlurOnProtocolLaunch);
		}

		let appUrl = CONSTANTS.EXTERNAL_URLS.SHAREKEY_APP_PROTOCOL;

		if (invitationCode)
			appUrl = `${CONSTANTS.EXTERNAL_URLS.SHAREKEY_APP_PROTOCOL}invitation-router?IC=${invitationCode}`;
		else if (userToConnectPID)
			appUrl = `${CONSTANTS.EXTERNAL_URLS.SHAREKEY_APP_PROTOCOL}member/${userToConnectPID}`;

		window.addEventListener('blur', handleBlurOnProtocolLaunch);

		if (isSafari)
			tryOpenApp(appUrl);
		else {
			setTimeout(
				() => tryOpenApp(appUrl),
				CONSTANTS.ADDITIONAL_TIME_TO_LOAD_FAVICON,
			);
		}

		setTimeout(
			() => window.removeEventListener('blur', handleBlurOnProtocolLaunch),
			CONSTANTS.TIME_TO_DETECT_DESKTOP,
		);
	};

	const handleNewLinkPattern = () => {
		const urlComponents = pathname.split('/').filter(string => !!string);
		const urlComponentWithCode = urlComponents[urlComponents.length - 1];
		const isInvitationCode = checkInvitationCodeFromURL(urlComponentWithCode);
		const isMemberKey = checkMemberKeyFromURL(urlComponentWithCode);

		// redirect to main sharekey website if the invitation code from the url is not valid
		if (isInvitationCode) {
			setIsInvitationCodeOnly(true);
			setInvitationCode(urlComponentWithCode);

			if (!isMobileOrTablet)
				processProtocolLaunch({ invitationCode: urlComponentWithCode });
		} else if (isMemberKey) {
			setIsMemberKeyOnly(true);
			setUserPID(urlComponentWithCode);

			if (!isMobileOrTablet)
				processProtocolLaunch({ userToConnectPID: urlComponentWithCode });
		} else
			window.location.replace(CONSTANTS.EXTERNAL_URLS.SHAREKEY_MAIN);
	};

	useEffect(() => {
		if (pathname === '/' && !search)
			window.location.replace(CONSTANTS.EXTERNAL_URLS.SHAREKEY_MAIN);
		else {
			if (isSafari)
				return handleNewLinkPattern();

			window.onload = handleNewLinkPattern;
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		try {
			if (isMobile)
				window?.screen?.orientation?.lock('portrait');
		} catch (error) {
			console.error(error);
		}
	}, [isMobile]);

	if (!invitationCode && !userPID)
		return null;

	const device = isTablet
		? 'tablet'
		: isMobileOnly
			? 'mobile'
			: 'desktop';
	const inviteOnlyClassName = isInvitationCodeOnly ? 'App--invite-only' : '';
	const appClassnames = `App App--${device} ${inviteOnlyClassName}`;
	const commonProps = {
		userPID,
		invitationCode,
		isInvitationCodeOnly,
		isMemberKeyOnly,
	};

	return (
		<div className={appClassnames}>
			{isMobile
				? <MobilePage {...commonProps} />
				: <DesktopAppInstalledContext.Provider
					value={{ desktopAppInstalledState, setDesktopAppInstalledState }}
				>
					<DesktopPage {...commonProps} />
				</DesktopAppInstalledContext.Provider>
			}
		</div>
	);
}

export default App;
