/* eslint-disable max-len */
import { FC } from 'react';

const LockIcon: FC = () => {
	return (
		<svg
			className='lock-icon'
			width='10'
			height='10'
			viewBox='0 0 10 10'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M6.35253 2.7068V3.80377H3.60866V2.70681C3.60866 1.94911 4.2229 1.33487 4.9806 1.33487C5.73829 1.33487 6.35253 1.94911 6.35253 2.7068ZM2.69957 2.70681C2.69957 1.44703 3.72082 0.425781 4.9806 0.425781C6.24037 0.425781 7.26162 1.44703 7.26162 2.7068V3.82963C8.11637 3.91745 8.7831 4.63968 8.7831 5.51764V7.69953C8.7831 8.63674 8.02334 9.3965 7.08613 9.3965H2.87666C1.93945 9.3965 1.17969 8.63674 1.17969 7.69953V5.51764C1.17969 4.64023 1.84558 3.91835 2.69957 3.8298V2.70681ZM2.87666 4.72976H7.08613C7.52126 4.72976 7.87401 5.0825 7.87401 5.51764V7.69953C7.87401 8.13467 7.52126 8.48741 7.08613 8.48741H2.87666C2.44152 8.48741 2.08878 8.13467 2.08878 7.69953V5.51764C2.08878 5.0825 2.44152 4.72976 2.87666 4.72976Z'
				fill='#242B2E'
			/>
		</svg>
	);
};

export default LockIcon;
