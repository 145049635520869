import { ReactElement } from 'react';
import { isMobile, isTablet } from 'react-device-detect';

import ConnectSection from '../ConnectSection';
import CreateAccountSection from '../CreateAccountSection';
import InstallSharekeySection from '../InstallSharekeySection';
import GetInvitedSection from '../GetInvitedSection';
import MobileHeader from '../MobileHeader';

import './main-content.scss';

type TProps = {
	userPID: string;
	invitationCode: string;
	isInvitationCodeOnly: boolean;
	isMemberKeyOnly: boolean;
};

function MainContent({ invitationCode, userPID, isInvitationCodeOnly, isMemberKeyOnly }: TProps): ReactElement {
	const baseClassName = 'main-content';
	const tabletComponentClassName = isTablet
		? `${baseClassName}--tablet`
		: '';
	const onlyInviteComponentClassName = isInvitationCodeOnly
		? `${baseClassName}--invite-only`
		: '';
	const onlyMemberKeyComponentClassName = isMemberKeyOnly
		? `${baseClassName}--member-key-only`
		: '';
	const componentClassName = `${baseClassName} ${tabletComponentClassName} ${onlyInviteComponentClassName} ${onlyMemberKeyComponentClassName}`;
	const sectionsContainerClassName = `${baseClassName}__sections-container`;
	const mainContentTextClassname = `${baseClassName}__text`;

	return (
		<div className={componentClassName}>
			{isMobile && <MobileHeader isMemberKeyOnly={isMemberKeyOnly} />}
			<div className={sectionsContainerClassName}>
				<InstallSharekeySection
					mainContentTextClassname={mainContentTextClassname}
					isMemberKeyOnly={isMemberKeyOnly}
				/>
				{isMemberKeyOnly
					? <GetInvitedSection mainContentTextClassname={mainContentTextClassname} />
					: <CreateAccountSection
						invitationCode={invitationCode}
						mainContentTextClassname={mainContentTextClassname}
					/>
				}
				{isMemberKeyOnly
					&& <ConnectSection
						userPID={userPID}
						mainContentTextClassname={mainContentTextClassname}
					/>
				}
			</div>
		</div>
	);
}

export default MainContent;
