import { ReactElement, useContext, useLayoutEffect } from 'react';
import { isMobileOnly, isTablet, isIOS } from 'react-device-detect';

import CONSTANTS from '../../constants';
import handleIndeterminateAppLinkClick from '../../helpers/handleIndeterminateAppLinkClick';
import tryOpenApp from '../../helpers/tryOpenApp';
import DesktopAppInstalledContext from '../../DesktopAppInstalledContext';

import './create-account-section.scss';

type TProps = {
	invitationCode: string;
	mainContentTextClassname: string;
};

function CreateAccountSection({
	invitationCode, mainContentTextClassname,
}: TProps): ReactElement {
	const { desktopAppInstalledState } = useContext(DesktopAppInstalledContext);
	const baseClassName = 'create-account-section';
	const classNames = {
		component: baseClassName,
		title: `${baseClassName}__title`,
		content: `${mainContentTextClassname} ${baseClassName}__content`,
		contentLink: `${baseClassName}__content-link`,
		contentCode: `${baseClassName}__content-code`,
		recommendation: `${baseClassName}__recommendation`,
	};
	const titleText = '2. Create Account & Connect';
	const linkLastPart = `/invite/${invitationCode}`;
	// TODO: change when merge into prod
	const invitationLinkWeb = `${CONSTANTS.EXTERNAL_URLS.SHAREKEY_APP}${linkLastPart}`;
	const invitationLinkDesktop = `${CONSTANTS.EXTERNAL_URLS.SHAREKEY_APP_PROTOCOL}${linkLastPart.slice(1)}`;
	const shortInvitationLink = `${CONSTANTS.EXTERNAL_URLS.SHAREKEY_APP_SHORT}${linkLastPart}`;

	const isMobileClient = isMobileOnly
		|| window.screen.width < CONSTANTS.MAX_PHONE_SCREEN_WIDTH;
	const isAppInstalled = desktopAppInstalledState === CONSTANTS.DESKTOP_APP_STATE.INSTALLED;
	const isAppIndeterminate = desktopAppInstalledState === CONSTANTS.DESKTOP_APP_STATE.INDETERMINATE;

	const onClickIndeterminateLink = (e: React.MouseEvent) =>
		handleIndeterminateAppLinkClick(e, invitationLinkDesktop, invitationLinkWeb);

	const onClickDesktopLink = () => tryOpenApp(invitationLinkDesktop);

	const linkOutput = isAppInstalled
		? <span
			className={classNames.contentLink}
			onClick={onClickDesktopLink}
		>
			{shortInvitationLink}
		</span>
		: <a
			className={classNames.contentLink}
			href={invitationLinkWeb}
			target='_blank'
			{...(isAppIndeterminate && { onClick: onClickIndeterminateLink })}
		>
			{shortInvitationLink}
		</a>;

	useLayoutEffect(() => {
		if (isTablet && isIOS)
			location.replace(invitationLinkWeb);
	}, [invitationLinkWeb]);

	return (
		<section className={classNames.component}>
			<h1 className={classNames.title}>
				{titleText}
			</h1>
			<div className={classNames.content}>
				Click&#8197;
				{linkOutput}
				{isMobileClient ? ' ' : <br/>}
				or use the Invitation Code: <span className={classNames.contentCode}>{invitationCode}</span>
			</div>
			<div className={classNames.recommendation}>
				Recommendation: create first your account on the Desktop App{isMobileClient ? ' ' : <br/>}
				and then Sign In on the Mobile App.
			</div>
		</section>
	);
}

export default CreateAccountSection;
